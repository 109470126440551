export const endpoint = "https://writefictif.com/be/staging";

export const assets_image_path = './images/assets_manager/';

export const IMAGE_ICON_PATH = '/images/icons/';
export const IMAGE_ICON_PATH_NEW = '/images/new_icons/';

export const APP_VERSION = "0.38";
export const GAME_INTERFACE_DEFAULT_IMAGE_PATH = '/images/game_interface/';
// //Qa Key
//export const CAPTCHA_SECURITY_TOKEN ='6LfEhvYUAAAAAOVr8l5lPVoiaG3I4YMrgdUQf_IM';

// Site Key V2
//export const CAPTCHA_SECURITY_TOKEN ='6LcTO_QUAAAAAHL1RMH1ZsW1GM9P2PT3p1y0aQzd';

// Site key v3
export const CAPTCHA_SECURITY_TOKEN = '6LfW8fgUAAAAANiHXgbAaJw4sGtbfvcK1y7Okk4B';

export const INVALID_LOGIN_ATTEMPTS_FORGOT = 3;
export const INVALID_LOGIN_ATTEMPTS_LOCKED = 4;
